import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import Header from '../components/header-main'
import Footer from '../components/footer'
import ExitIntent from '../components/ExitIntent'

import '../styles/index.css'

// process.env.GATSBY_ACTIVE_ENV === 'production' && typeof window !== "undefined" && typeof window.gtag !== "undefined" && window.gtag('event', 'conversion', {'send_to': 'AW-XXXX'})


const PageWrapper = styled.div`
  background: #69D49F;
  min-height: 100vh;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 940px;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  padding: 60px 120px 0;
  
  @media only screen and (max-width : 600px) {
    padding: 60px 7% 0;
  } 
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: 90vh;
  overflow: scroll;
  background: white;
`

const ContentTitle = styled.h1`
  font-size: 42px;
  font-weight: normal;
  margin-bottom: 4px;
  
  @media only screen and (max-width : 480px) {
    font-size: 36px;
  } 
`

const ContentSubTitle = styled.h2`
  font-size: 28px;
  font-weight: 200;
  line-height: 34px;
  color: #707070;
  margin-bottom: 15px;

  @media only screen and (max-width : 480px) {
    font-size: 28px;
  } 
`

const ContentDate = styled.h2`
  font-size: 13px;
  font-weight: lighter;
  color: #999;
`

const Content = styled.div`
  font-size: 21px;
  line-height: 1.58;
  font-weight: 300;

  p {
    margin-bottom: 22px;
  }

  li {
    margin-bottom: 2px;
    margin-left: 20px;
  }

  h4 {
    margin-top: 40px;
  }
  
  @media only screen and (max-width : 480px) {
    font-size: 18px;
    line-height: 28.5px;
    
    ul {
      margin-left: 10px;
    } 
    
    li {
      margin-bottom: 8px;
      margin-left: 0;
    }
  } 
`

function DefaultTemplate({ data: { mdx, site } }) {
  return (
    <PageWrapper>
      <Helmet
        title={mdx.frontmatter.title}
        meta={[
          {
            name: "description",
            content: mdx.frontmatter.description,
          },
          {
            name: "keywords",
            content: mdx.frontmatter.keywords,
          },
        ]}
        link={[]}
        defer={false}
      />
      <Header siteTitle={site.siteMetadata.title} />
      <ContentWrapper>
        <Container>
          <ContentTitle>{mdx.frontmatter.title}</ContentTitle>
          { mdx.frontmatter.subtitle ? (
            <ContentSubTitle>{mdx.frontmatter.subtitle}</ContentSubTitle>
          ) : null }
          <ContentDate>{mdx.frontmatter.date} {mdx.frontmatter.timing ? `• ${mdx.frontmatter.timing} read` : null }</ContentDate>
          <Content>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </Content>
        </Container>
      </ContentWrapper>
      <Footer />
      <ExitIntent />
    </PageWrapper>
  )
}

export const pageQuery = graphql`
  query DefaultQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        subtitle
        date
        timing
        description
        keywords
      }
    },
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default DefaultTemplate
